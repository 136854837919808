<template>
  <div class="px-4 w-full dF fC f1 pb-4 hide-scrollbar" >
      <bh-loading :show="loading" />
      <SettingModal />
      <a-modal :visible="previewModal.visible" @cancel="closeModal" :footer="null" centered>
            <div class="mt-3">
                <img style="height:100%; width:100%" :src="previewModal.item" />
            </div>
        </a-modal>
      <div class="scrollingElement dF" style="justify-content:space-between; overflow-y:scroll">
        <div class="w-full">
            <a-tabs default-active-key="1" @change="tabName" size="large">
            <a-tab-pane key="1" tab="Unit Details">
                <div class="hide-scrollbar">
                    <div style="width:100%;">
                        <div class="dF">
							<a-icon @click="backButton" class="mr-4 mt-2" style="font-size:1.75rem; cursor:pointer" type="arrow-left" />
							<h2>
								Back to {{ newUnit.unitGroup && newUnit.unitGroup.name || 'Floors'}}
								<br/>
								<h5 style="display: inline-block;">{{ newUnit.unitNumber ? `Unit ${newUnit.unitNumber}` : newUnit.name }}</h5>
							</h2>
                        </div>

                        <div class="w-full dF mt-3">
                            <div class="f1" style="padding-right: 8px; max-width: calc(100% - 350px);">
                                <div style="background-color: #FFF;">
                                    <p class="ml-3" style="font-size:15px; padding-top:15px"><strong>Basic Information</strong></p><hr>
                                    <a-form-model :model="newUnit" ref="newUnit" class="pl-3 pr-3">
                                        <a-row :gutter="16">
                                            <a-col :span="12">
                                                <a-form-model-item label="Define Condo Type" required>
                                                    <a-select v-model="newUnit.packages[0].hasCondo" @change="changeCondoType" style="width:100%;">
                                                        <a-select-option :value="'false'">
                                                            Condo Unit
                                                        </a-select-option>
                                                        <a-select-option :value="'true'">
                                                            Condo Town
                                                        </a-select-option>
                                                    </a-select>
                                                </a-form-model-item>
                                            </a-col>
                                        </a-row>
                                        <a-row :gutter="16">
                                            <a-col :span="6" >
                                                <a-form-model-item label="Unit Code" prop="unitCode">
                                                    <a-input v-model="newUnit.unitCode" placeholder="Internal code"></a-input>
                                                </a-form-model-item>
                                            </a-col>
                                            <a-col :span="6" >
                                                <a-form-model-item label="Unit No. (Alphanumeric)" required :rules="req('Please enter Unit No.')" prop="unitNumber">
                                                    <a-input v-model="newUnit.unitNumber" placeholder="Example value: 101" :min="0"></a-input>
                                                </a-form-model-item>
                                            </a-col>
                                            <a-col :span="6" >
                                                <a-form-model-item label="Marketing Name" prop="name">
                                                    <a-input v-model="newUnit.name" placeholder="Example: Unit Name"></a-input>
                                                </a-form-model-item>
                                            </a-col>
                                            <a-col :span="6">
                                                <a-form-model-item label="Suffix" prop="suffix">
                                                    <a-input v-model="newUnit.suffix" placeholder="Example: Alpha/Numeric"></a-input>
                                                </a-form-model-item>
                                            </a-col>
                                        </a-row>
										<a-row :gutter="16">
											<a-col :span="8">
												<a-form-model-item
													label="Street Number"
													prop="streetNumber"
												>
													<a-input
														:min="0"
														type="number"
														v-model="newUnit.streetNumber"
														placeholder="Street Number"
													></a-input>
												</a-form-model-item>
											</a-col>
											<a-col :span="8">
												<a-form-model-item label="Street Name" prop="street">
													<a-auto-complete
														v-model="newUnit.street"
														placeholder="Street Name"
													/>
												</a-form-model-item>
											</a-col>
											<a-col :span="8">
												<a-form-model-item
													:label="
														country === 'CA' ? 'Postal Code' : 'Zip Code'
													"
													prop="postal"
												>
													<a-input
														v-model="newUnit.postal"
														:placeholder="
															country === 'CA'
																? 'Postal Code'
																: 'Zip Code'
														"
													></a-input>
												</a-form-model-item>
											</a-col>
										</a-row>
										<a-row :gutter="16">
											<a-col :span="8">
												<a-form-model-item label="City" prop="city">
													<a-input
														v-model="newUnit.city"
														placeholder="City"
													></a-input>
												</a-form-model-item>
											</a-col>
											<a-col :span="8">
												<a-form-model-item
													:label="country === 'CA' ? 'Province' : 'State'"
													prop="region"
												>
													<a-input
														v-model="newUnit.region"
														:placeholder="
															country === 'CA' ? 'Province' : 'State'
														"
													></a-input>
												</a-form-model-item>
											</a-col>
											<a-col :span="8">
												<a-form-model-item v-if="newUnit.packages[0].hasCondo == 'false'" label="Floor Level" required>
													<a-select v-model="unitGroup" style="width:100%">
														<a-select-option v-for="(floor) in floors" :key="floor.name" :value="floor.id">{{floor.name}}</a-select-option>
													</a-select>
												</a-form-model-item>
												<a-form-model-item v-else label="Storey" required>
													<a-select v-model="newUnit.packages[0].stories" style="width:100%">
														<a-select-option v-for="(i) in 5" :key="i" :value="i">{{i}}</a-select-option>
													</a-select>
												</a-form-model-item>
											</a-col>
										</a-row>
                                        <a-row :gutter="16">
                                            <a-col :span="6" >
                                                <a-form-model-item label="Unit Price">
                                                    <a-input-number style="width:100%" v-model="newUnit.packages[0].price" :formatter="price => `$ ${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0" placeholder="Example value: 500000/500,000" >
                                                    </a-input-number>
                                                </a-form-model-item>
                                            </a-col>
                                            <a-col :span="6" >
                                                <a-form-model-item label="Interior Square Footage" required prop="packages.0.sqft" :rules="req('Please enter Interior Footage')">
                                                    <a-input-number style="width:100%" v-model="newUnit.packages[0].sqft" :formatter="price => `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0" placeholder="Example value: 1500/1,500" >
                                                    </a-input-number>
                                                </a-form-model-item>
                                            </a-col>
                                            <a-col :span="6">
                                                <a-form-model-item label="Exterior Square Footage" prop="packages.0.extSqft">
                                                    <a-input-number style="width:100%" v-model="newUnit.packages[0].extSqft" :formatter="price => `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0" placeholder="Example value: 1500/1,500" >
                                                    </a-input-number>
                                                </a-form-model-item>
                                            </a-col>
											<a-col :span="6">
                                                <a-form-model-item label="Price Per Sq. Ft." >
                                                    <a-input-number disabled style="width:100%" :value="newUnit.packages[0].sqft > 0 && newUnit.packages[0].price ? (newUnit.packages[0].price/newUnit.packages[0].sqft).toFixed(2) : 0" :formatter="price => `$ ${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0" placeholder="Price" >
                                                    </a-input-number>
                                                </a-form-model-item>
                                            </a-col>
                                        </a-row>
                                        <a-row :gutter="16">
                                            <a-col :span="8">
                                                <a-form-model-item label="Exposure" prop="packages.0.exposure">
                                                    <a-select v-model="newUnit.packages[0].exposure" style="width:100%">
                                                        <a-select-option v-for="view in views" :key="view.value">{{ view.label }}</a-select-option>
                                                    </a-select>
                                                </a-form-model-item>
                                            </a-col>
                                            <a-col :span="8">
                                                <a-form-model-item :label="`Ceiling Height (${measurement})`" prop="packages.0.ceilHeight">
                                                    <a-input-number style="width:100%" v-model="newUnit.packages[0].ceilHeight" :formatter="price => `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0" placeholder="Example value: 1" >
                                                    </a-input-number>
                                                </a-form-model-item>
                                            </a-col>
											<a-col :span="8">
                                                <a-form-model-item label="Bedrooms (Alphanumeric)" required prop="packages.0.beds" :rules="req('Please enter Number of Beds')">
                                                    <a-input-number style="width:100%" v-model="newUnit.packages[0].beds" :formatter="price => `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0" placeholder="Example value: 1" >
                                                    </a-input-number>
                                                </a-form-model-item>
                                            </a-col>
                                        </a-row>
                                        <a-row :gutter="16">
                                            <a-col :span="8">
                                                <a-form-model-item label="Bathrooms (Alphanumeric)" required prop="packages.0.baths" :rules="req('Please enter Number of Baths')">
                                                    <a-input-number style="width:100%" v-model="newUnit.packages[0].baths" :formatter="price => `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0" placeholder="Example value: 1" >
                                                    </a-input-number>
                                                </a-form-model-item>
                                            </a-col>
											<a-col :span="8">
                                                <a-form-model-item label="Powder Rooms (Alphanumeric)" required prop="packages.0.powderRooms" :rules="req('Please enter Number of Powder Rooms')">
                                                    <a-input-number style="width:100%" v-model="newUnit.packages[0].powderRooms" :formatter="price => `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0" placeholder="Example value: 1" >
                                                    </a-input-number>
                                                </a-form-model-item>
                                            </a-col>
                                            <a-col :span="8">
                                                <a-form-model-item v-if="newUnit.packages[0].hasCondo == 'false'" label="Den" prop="packages.0.hasDen">
                                                    <a-select v-model="newUnit.packages[0].hasDen" style="width: 100%;">
                                                        <a-select-option :value="'true'">
                                                            Yes
                                                        </a-select-option>
                                                        <a-select-option :value="'false'">
                                                            No
                                                        </a-select-option>
                                                    </a-select>
                                                </a-form-model-item>
                                                <a-form-model-item v-else label="Garage" prop="packages.0.garages">
                                                    <a-input-number style="width:100%" v-model="newUnit.packages[0].garages" :formatter="price => `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0" placeholder="Example value: 1" >
                                                    </a-input-number>
                                                </a-form-model-item>
                                            </a-col>
                                        </a-row>
                                        <a-row :gutter="16">
                                            <a-col :span="8">
                                                <a-form-model-item label="Max Number of Parking Spots (Numeric)" required prop="packages.0.maxParking" :rules="req('Please enter Max Number of Parking Spots')">
                                                    <a-input-number style="width:100%" v-model="newUnit.packages[0].maxParking" :formatter="price => `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0" placeholder="Example value: 1" >
                                                    </a-input-number>
                                                </a-form-model-item>
                                            </a-col>
                                            <a-col :span="8">
                                                <a-form-model-item label="Max Number of Lockers (Numeric)" required prop="packages.0.maxLocker" :rules="req('Please enter Max Number of Lockers')">
                                                    <a-input-number style="width:100%" v-model="newUnit.packages[0].maxLocker" :formatter="price => `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0" placeholder="Example value: 1" >
                                                    </a-input-number>
                                                </a-form-model-item>
                                            </a-col>
                                            <a-col :span="8">
                                                <a-form-model-item label="Max Number of Bike Racks (Numeric)" required prop="packages.0.maxBikeRack" :rules="req('Please enter Max Number of Bike Racks')">
                                                    <a-input-number style="width:100%" v-model="newUnit.packages[0].maxBikeRack" :formatter="price => `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0" placeholder="Example value: 1" >
                                                    </a-input-number>
                                                </a-form-model-item>
                                            </a-col>
                                        </a-row>

										<div class="mt-4 dF aE jSB">
                                        	<div style="font-size:15px; line-height: 1;"><strong>Floor Plan</strong></div>
											<div class="dF aC">
												<div>Show Marketing Floorplans?</div>
												<a-switch class="ml-2" v-model="newUnit.showMarketingFloors"></a-switch>
											</div>
										</div>
                                        <hr>
                                        <div class="w-full" style="background-color: #FFF;">
                                            <FloorImage :galleryImages="imageArray" :penthouseImage="newUnit.packages[0].penthouseImage" @penthouseImage="updatePenthouseImage" @terraceImage="updateTerraceImage" :hasTerrace="newUnit.packages[0].hasTerrace" :hasPenthouse="newUnit.packages[0].hasPenthouse" @hasPenthouse="updateHasPenthouse" @hasTerrace="updateHasTerrace" :terraceImage="newUnit.packages[0].terraceImage" @previewGallery="previewGallery" @galleryImages="galleryImages" :storey="newUnit.packages[0].stories ? newUnit.packages[0].stories : 1" :condoTown="newUnit.packages[0].hasCondo == 'true' ? true : false" />
                                        </div>

										<template v-if="newUnit.showMarketingFloors">
											<div class="mt-5" style="font-size:15px; line-height: 1;"><strong>Marketing Floor Plan</strong></div>
											<hr>
											<div class="w-full" style="background-color: #FFF;">
												<FloorImage :isMarketing="true" :galleryImages="imageArray2" :penthouseImage="newUnit.packages[0].penthouseImage2" @penthouseImage="updatePenthouseImage2" @terraceImage="updateTerraceImage2" :hasTerrace="newUnit.packages[0].hasTerrace" :hasPenthouse="newUnit.packages[0].hasPenthouse" @hasPenthouse="updateHasPenthouse" @hasTerrace="updateHasTerrace" :terraceImage="newUnit.packages[0].terraceImage2" @previewGallery="previewGallery" @marketingImages="marketingImages" :storey="newUnit.packages[0].stories ? newUnit.packages[0].stories : 1" :condoTown="newUnit.packages[0].hasCondo == 'true' ? true : false" />
											</div>
										</template>



                                        <p class="mt-5" style="font-size:15px; line-height: 1;"><strong>Custom Fields</strong></p>
                                        <hr>
                                        <a-row :gutter="24" type="flex" justify="start">
                                            <a-col :span="8" v-for="field in Object.values(fields)" :key="field.id">
                                                <a-form-model-item :label="field.name" :prop="`packages[0].other.cf.${field.id}`" :required="!!field.required" :rules="req(`Please enter ${field.name}`, !!field.required)">
                                                    <a-input :placeholder="field.defaultAnswer" v-if="field.type == 'text'" :default-value="field.defaultAnswer" v-model="newUnit.packages[0].other.cf[field.id]" @blur="fieldChanged" :key="updateField + field.id"/>
													<a-input v-if="field.type == 'number'" type="number" v-model="newUnit.packages[0].other.cf[field.id]" :default-value="Number(field.defaultAnswer || 0)" @blur="fieldChanged" :key="updateField + field.id"/>
													<a-date-picker v-else-if="field.type == 'date'" style="width:100%" :default-value="newUnit.packages[0].other.cf[field.id] ? moment(newUnit.packages[0].other.cf[field.id]) : moment(field.defaultAnswer || new Date())" @change="(e) => applyMulField(e, field.id)"/>
													<template v-else-if="field.type == 'checkbox'">
														<a-checkbox v-for="(option, optionI) in field.options" :value="option" :key="optionI+updateField" @change="(e) => changeCheck(e, field.id)" :checked="option == (newUnit.packages[0].other.cf[field.id] || field.defaultAnswer)">{{option}}</a-checkbox>
													</template>
                                                    <template v-if="!$route.params.id || Object.keys($route.params).length == 0 || !newUnit.packages[0].other.cf[field.id] || !newUnit.packages[0].other || !newUnit.packages[0].other.cf">
                                                    <a-select @change="(e) => applyMulField(e,field.id)" :default-value="field.defaultAnswer" :mode="field.multipleAnswer? 'multiple' : ''" v-if="field.type == 'multipleChoice'" >
                                                        <a-select-option v-for="(option, optionI) in field.options" :key="(option+optionI)" :value="option">{{option}}</a-select-option>
                                                    </a-select>
                                                    </template>
                                                    <template v-else-if="$route.params.id && $route.params.id != '' && newUnit.packages[0].other.cf[field.id] && newUnit.packages[0].other && newUnit.packages[0].other.cf">
                                                    <a-select :mode="field.multipleAnswer? 'multiple' : ''" v-if="field.type == 'multipleChoice'" v-model="newUnit.packages[0].other.cf[field.id]">
                                                        <a-select-option v-for="(option, optionI) in field.options" :key="(option+optionI)" :value="option">{{option}}</a-select-option>
                                                    </a-select>
                                                    </template>
                                                </a-form-model-item>
                                            </a-col>
                                        </a-row>
                                        <a-button style="margin-bottom:24px" @click="addCustomField" type="primary" size="large">ADD CUSTOM FIELD</a-button>

                                        <p class="mt-4" style="font-size:15px; line-height: 1;"><strong>Unit Add Ons</strong> (If Predefined)</p>
                                        <hr>
                                        <a-tabs v-model="currentTab" :tabBarStyle="{'border-bottom': 'none'}">
                                            <!-- Parking -->
                                            <a-tab-pane key="parking" tab="Parking">
                                                <template v-if="newUnit.packages[0].other && newUnit.packages[0].other.addons && newUnit.packages[0].other.addons.parking">
                                                    <a-row :gutter="16" v-for="(addon, addonI) in newUnit.packages[0].other.addons.parking" :key="addon+addonI" class="dF aC">
                                                        <a-col :span="4">
                                                            <a-form-model-item label="Parking ID" prop="packages.0.other.addons.parking.pID">
                                                                <a-input style="width:100%" size="large" v-model="newUnit.packages[0].other.addons.parking[addonI].pID" placeholder="Example value: 1" >
                                                                </a-input>
                                                            </a-form-model-item>
                                                        </a-col>
                                                        <a-col :span="4">
                                                            <a-form-model-item label="Parking Spot #" prop="packages.0.other.addons.parking.name">
                                                                <a-input style="width:100%" size="large" v-model="newUnit.packages[0].other.addons.parking[addonI].name" placeholder="Example : P101" >
                                                                </a-input>
                                                            </a-form-model-item>
                                                        </a-col>
														<a-col :span="4">
                                                            <a-form-model-item label="Parking Type" prop="packages.0.other.addons.parking.type">
                                                                <a-select
                                                                    v-model="newUnit.packages[0].other.addons.parking[addonI].type"
                                                                    size="large"
                                                                    style="width: 100%"
                                                                    option-label-prop="label"
                                                                >
                                                                    <a-select-option value="Regular" label="Regular">Regular</a-select-option>
                                                                    <a-select-option value="EV" label="EV">EV</a-select-option>
																	<a-select-option value="Tandem" label="Tandem">Tandem</a-select-option>
                        											<a-select-option value="Extra Large" label="Extra Large">Extra Large</a-select-option>
                                                                </a-select>
                                                            </a-form-model-item>
                                                        </a-col>
                                                        <a-col :span="4">
                                                            <a-form-model-item label="Parking Spot Price" prop="packages.0.other.addons.parking.price">
                                                                <a-input-number style="width:100%" size="large" v-model="newUnit.packages[0].other.addons.parking[addonI].price" :formatter="price => `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0" placeholder="Example value: 1" >
                                                                </a-input-number>
                                                            </a-form-model-item>
                                                        </a-col>
                                                        <a-col :span="4">
                                                            <a-form-model-item label="Description" prop="packages.0.other.addons.parking.description">
                                                                <a-input size="large" v-model="newUnit.packages[0].other.addons.parking[addonI].description" placeholder="Parking Spot Description"></a-input>
                                                            </a-form-model-item>
                                                        </a-col>
                                                        <a-col :span="3">
                                                            <a-form-model-item label="Tag(s)" prop="packages.0.other.addons.parking.tags">
                                                                <a-select
                                                                    v-model="newUnit.packages[0].other.addons.parking[addonI].tags"
                                                                    mode="tags"
                                                                    size="large"
                                                                    style="width: 100%"
                                                                    placeholder="Tags"
                                                                    option-label-prop="label"
                                                                >
                                                                </a-select>
                                                            </a-form-model-item>
                                                        </a-col>
                                                        <a-col :span="2">
                                                            <svg @click="deleteAddon(newUnit.packages[0].other.addons.parking[addonI].id,'parking')" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="var(--danger)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2" style="cursor: pointer;"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                                        </a-col>
                                                    </a-row>
                                                </template>
                                            </a-tab-pane>


                                            <!-- Locker -->
                                            <a-tab-pane key="lockers" tab="Locker">
                                                <template v-if="newUnit.packages[0].other && newUnit.packages[0].other.addons && newUnit.packages[0].other.addons.lockers">
                                                    <a-row :gutter="16" v-for="(addon, addonI) in newUnit.packages[0].other.addons.lockers" :key="addon+addonI" class="dF aC">
                                                        <a-col :span="4">
                                                            <a-form-model-item label="Locker ID" prop="packages.0.other.addons.lockers.lID">
                                                                <a-input style="width:100%" size="large" v-model="newUnit.packages[0].other.addons.lockers[addonI].lID" placeholder="Example value: 1" >
                                                                </a-input>
                                                            </a-form-model-item>
                                                        </a-col>
                                                        <a-col :span="4">
                                                            <a-form-model-item label="Locker #" prop="packages.0.other.addons.lockers.name">
                                                                <a-input style="width:100%" size="large" v-model="newUnit.packages[0].other.addons.lockers[addonI].name" placeholder="Example value: L101" >
                                                                </a-input>
                                                            </a-form-model-item>
                                                        </a-col>
														<a-col :span="3">
                                                            <a-form-model-item label="Locker Type" prop="packages.0.other.addons.lockers.type">
                                                                <a-select
                                                                    v-model="newUnit.packages[0].other.addons.lockers[addonI].type"
                                                                    size="large"
                                                                    style="width: 100%"
                                                                    option-label-prop="label"
                                                                >
                                                                    <a-select-option value="Regular" label="Regular">Regular</a-select-option>
                        											<a-select-option value="Large" label="Large">Large</a-select-option>
                                                                </a-select>
                                                            </a-form-model-item>
                                                        </a-col>
                                                        <a-col :span="4">
                                                            <a-form-model-item label="Locker Price" prop="packages.0.other.addons.lockers.price">
                                                                <a-input-number style="width:100%" size="large" v-model="newUnit.packages[0].other.addons.lockers[addonI].price" :formatter="price => `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0" placeholder="Example value: 1" >
                                                                </a-input-number>
                                                            </a-form-model-item>
                                                        </a-col>
                                                        <a-col :span="4">
                                                            <a-form-model-item label="Description" prop="packages.0.other.addons.lockers.description">
                                                                <a-input size="large" v-model="newUnit.packages[0].other.addons.lockers[addonI].description" placeholder="Locker Description"></a-input>
                                                            </a-form-model-item>
                                                        </a-col>
                                                        <a-col :span="4">
                                                            <a-form-model-item label="Tag(s)" prop="packages.0.other.addons.tags">
                                                                <a-select
                                                                    v-model="newUnit.packages[0].other.addons.lockers[addonI].tags"
                                                                    mode="tags"
                                                                    size="large"
                                                                    style="width: 100%"
                                                                    placeholder="Tags"
                                                                    option-label-prop="label"
                                                                >
                                                                </a-select>
                                                            </a-form-model-item>
                                                        </a-col>
                                                        <a-col :span="2">
                                                            <svg @click="deleteAddon(newUnit.packages[0].other.addons.lockers[addonI].id,'lockers')" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="var(--danger)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2" style="cursor: pointer;"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                                        </a-col>
                                                    </a-row>
                                                </template>
                                            </a-tab-pane>


                                            <!-- Bike Rack -->
                                            <a-tab-pane key="bikeRacks" tab="Bike Rack">
                                                <template v-if="newUnit.packages[0].other && newUnit.packages[0].other.addons && newUnit.packages[0].other.addons.bikeRacks">
                                                    <a-row :gutter="16" v-for="(addon, addonI) in newUnit.packages[0].other.addons.bikeRacks" :key="addon+addonI" class="dF aC">
                                                        <a-col :span="4">
                                                            <a-form-model-item label="Bike Rack ID" prop="packages.0.other.addons.bikeRacks.bID">
                                                                <a-input style="width:100%" size="large" v-model="newUnit.packages[0].other.addons.bikeRacks[addonI].bID" placeholder="Example value: 1" >
                                                                </a-input>
                                                            </a-form-model-item>
                                                        </a-col>
                                                        <a-col :span="4">
                                                            <a-form-model-item label="Bike Rack #" prop="packages.0.other.addons.bikeRacks.name">
                                                                <a-input style="width:100%" size="large" v-model="newUnit.packages[0].other.addons.bikeRacks[addonI].name" placeholder="Example value: B101" >
                                                                </a-input>
                                                            </a-form-model-item>
                                                        </a-col>
                                                        <a-col :span="5">
                                                            <a-form-model-item label="Bike Rack Price" prop="packages.0.other.addons.bikeRacks.price">
                                                                <a-input-number style="width:100%" size="large" v-model="newUnit.packages[0].other.addons.bikeRacks[addonI].price" :formatter="price => `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0" placeholder="Example value: 1" >
                                                                </a-input-number>
                                                            </a-form-model-item>
                                                        </a-col>
                                                        <a-col :span="5">
                                                            <a-form-model-item label="Description" prop="packages.0.other.addons.bikeRacks.description">
                                                                <a-input size="large" v-model="newUnit.packages[0].other.addons.bikeRacks[addonI].description" placeholder="Bike Rack Description"></a-input>
                                                            </a-form-model-item>
                                                        </a-col>
                                                        <a-col :span="4">
                                                            <a-form-model-item label="Tag(s)" prop="packages.0.other.addons.bikeRacks.tags">
                                                                <a-select
                                                                    v-model="newUnit.packages[0].other.addons.bikeRacks[addonI].tags"
                                                                    mode="tags"
                                                                    size="large"
                                                                    style="width: 100%"
                                                                    placeholder="Tags"
                                                                    option-label-prop="label"
                                                                >
                                                                </a-select>
                                                            </a-form-model-item>
                                                        </a-col>
                                                        <a-col :span="2">
                                                            <svg @click="deleteAddon(newUnit.packages[0].other.addons.bikeRacks[addonI].id,'bikeRacks')" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="var(--danger)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2" style="cursor: pointer;"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                                        </a-col>
                                                    </a-row>
                                                </template>
                                            </a-tab-pane>
                                        </a-tabs>
                                        <a-row :gutter="16" class="mt-3">
                                            <a-col :span="24">
                                                <a-button style="margin-bottom:24px" @click="addAddon" type="primary" size="large">ADD NEW</a-button>
                                            </a-col>
                                        </a-row>




                                        <p class="mt-4" style="font-size:15px; line-height: 1;"><strong>Unit Premiums</strong></p>
                                        <hr>
                                        <a-row :gutter="16">
                                            <a-col :span="8" v-for="premium in premiums" :key="premium.id">
                                                <a-form-model-item :label="premium.name">
                                                    <div class="dF aC">
                                                        <a-checkbox v-if="newUnit.packages[0].other.premiums[premium.id]" class="mr-2"  v-model="newUnit.packages[0].other.premiums[premium.id].active" />
                                                        <a-input-number v-if="newUnit.packages[0].other.premiums[premium.id]" style="width:100%" v-model="newUnit.packages[0].other.premiums[premium.id].value" :default-value="premium.value" :formatter="price => `$ ${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0" :placeholder="premium.value.toString()" >
                                                        </a-input-number>
                                                    </div>
                                                </a-form-model-item>
                                            </a-col>
                                        </a-row>
                                        <a-button @click="addPremium" size="large" type="primary" style="margin-bottom:24px">ADD UNIT PREMIUMS</a-button>




                                        <p class="mt-4" style="font-size:15px; line-height: 1;"><strong>Official Information / Occupancy and Fees</strong></p>
                                        <hr>
                                        <a-row :gutter="16">
                                            <a-col :span="8" >
                                                <a-form-model-item label="Tentative/Firm Close Date" prop="packages.0.occupancy">
                                                    <a-date-picker style="width:100%" v-model="newUnit.packages[0].occupancy" placeholder="YYYY-MM-DD"></a-date-picker>
                                                </a-form-model-item>
                                            </a-col>
                                            <a-col :span="8" >
                                                <a-form-model-item label="Estimated Property Tax (Only Digits)" prop="packages.0.tax">
                                                    <a-input-number style="width:100%" v-model="newUnit.packages[0].tax" :formatter="price => `$ ${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0" placeholder="Example value: 90000/90,000" >
                                                    </a-input-number>
                                                </a-form-model-item>
                                            </a-col>
                                            <a-col :span="8" >
                                                <a-form-model-item label="Estimated Maintenance (Only Digits)" prop="packages.0.maintenance">
                                                    <a-input-number style="width:100%" v-model="newUnit.packages[0].maintenance" :formatter="price => `$ ${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0" placeholder="Example value: 400" >
                                                    </a-input-number>
                                                </a-form-model-item>
                                            </a-col>
                                        </a-row>

                                        <a-row :gutter="16" class="mt-4">
                                            <a-col :span="24">
                                                <a-form-model-item prop="pdf">
                                                    <div class="dF aC">
                                                        <div class="mr-2" style="line-height: 1; font-size:15px"><strong>Downloadable Floor Plan</strong></div>
                                                    </div>
                                                    <hr>
                                                    <div>
                                                        <div class="dF" style="justify-content:space-between">
                                                            <a-input :disabled="true" style="flex: 1;" v-model="newUnit.pdf"></a-input>
						  									<a-button v-if="newUnit.pdf" @click="newUnit.pdf = ''" type="danger" class="ml-3"><a-icon type="delete"></a-icon></a-button>
                                                            <a-button @click="$store.commit('MEDIA_SELECT',{callback:selectedPdf,type:'pdf'})" class="ml-3" style="background-color: #ECE9F1;" ><a-icon type="plus" />ADD PDF</a-button>
                                                        </div>
                                                        <small>Maximum upload file size: 5MB.</small>
                                                    </div>
                                                </a-form-model-item>
                                            </a-col>
                                            <a-col :span="24">
                                                <a-form-model-item prop="features">
                                                    <div class="dF aC">
                                                        <div class="mr-2" style="line-height: 1; font-size:15px"><strong>Features and Finishes</strong></div>
                                                    </div>
                                                    <hr>
                                                    <div>
                                                        <div class="dF" style="justify-content:space-between">
                                                            <a-input :disabled="true" style="flex: 1;" v-model="newUnit.features"></a-input>
															<a-button v-if="newUnit.features" @click="newUnit.features = ''" type="danger" class="ml-3"><a-icon type="delete"></a-icon></a-button>
                                                            <a-button @click="$store.commit('MEDIA_SELECT',{callback:selectedFeaturePDF,type:'pdf'})" class="ml-3" style="background-color: #ECE9F1;" ><a-icon type="plus" />ADD PDF</a-button>
                                                        </div>
                                                        <small>Maximum upload file size: 5MB.</small>
                                                    </div>
                                                </a-form-model-item>
                                            </a-col>
                                        </a-row>
                                    </a-form-model>
                                </div>
                            </div>

                            <div style="padding-left:8px; width: 350px" class="testtt relative">
                                <div :class="toggleClass()">
                                    <div id="stickyNav" style="background-color:#fff;">
                                        <p class="pt-3 pl-3" style="font-size:15px"><strong>Status and Visibility</strong></p><hr style="margin-bottom:0px">
                                        <div style="padding:24px 10px">
                                            <div class="mb-3">
                                                <a-tooltip :title="$p < 40 ? 'You do not have permission to modify this status' : ''" overlayClassName="change-tooltip-color">
                                                    <a-select v-model="newUnit.status" style="width:100%" :disabled="$p < 40">
                                                        <a-select-option v-for="vis in status" :key="vis.value">{{ vis.label }}</a-select-option>
                                                    </a-select>
                                                </a-tooltip>
                                            </div>
                                            <div class="dF aC" style="justify-content:space-between">
                                                <h6 @click="deleteUnit" v-if="this.$route.params.id != null"  style="color:var(--orange); margin-bottom:0; text-decoration:underline; cursor:pointer">DELETE UNIT</h6>
                                                <div v-else></div>
                                                <a-button @click="submit" type="primary" style="width:120px;"  size="large">{{!this.$route.params.id ? 'SAVE' : 'UPDATE'}}</a-button>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="background-color:#fff;" class="mt-5">
                                        <div class="pl-3 pt-3 dF aC">
                                            <span class="mr-2"><strong>Thumbnail Image</strong></span>
                                            <a-tooltip title="If you want to display something other then the main floor plan image, upload here." overlayClassName="change-tooltip-color">
                                                <svg style="color:var(--orange)" viewBox="64 64 896 896" data-icon="question-circle" width="15" height="15" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0 1 30.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1 0 80 0 40 40 0 1 0-80 0z"></path></svg>
                                            </a-tooltip>
                                        </div>
                                        <hr style="margin-bottom:0px">
                                        <div style="padding:24px 10px">
                                            <ImageBox :img="newUnit.image" @callback="floorPlanImg" @previewImage="previewGallery" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="Media">
                <h3 class="mb-3" :style="!newUnit.name ? 'color:var(--med-gray)':''">{{ newUnit.name? newUnit.name : 'Unnamed Unit' }}</h3>
                <div class="w-full dF mt-3">
                    <div class="f1" style="padding-right: 8px; max-width: calc(100% - 350px);">
                        <GalleryImage :galleryImages="newUnit.media.mediaImage" @previewGallery="previewGallery" @galleryImages="galleryImg" :title="'Gallery Images'" />
                        <div style="background-color:#fff; width:100%;" class="mt-5">
                            <div>
                                <p class="pt-3 pl-3" style="font-size:15px"><strong>Videos</strong></p><hr>
                            </div>
                            <div class="pl-3 pr-3">
                                <div>
                                    <a-form-model-item label="Virtual Tour Video URL" >
                                        <a-input v-model="newUnit.media.mediaVideo"></a-input>
                                    </a-form-model-item>
                                </div>
								<a-form-model-item label="360 Virtual Tour">
									<div>
										<a-input v-model="newUnit.media.media360"></a-input>
										<small>Provide iframe embed code for the 360 virtual tour.</small>
									</div>
								</a-form-model-item>
                            </div>
                        </div>
                    </div>
                    <div style="padding-left:8px; width: 350px" class="testtt relative">
                        <div :class="toggleClass()">
                            <div id="stickyNav" style="background-color:#fff;">
                                <p class="pt-3 pl-3" style="font-size:15px"><strong>Status and Visibility</strong></p><hr style="margin-bottom:0px">
                                <div style="padding:24px 10px">
                                    <div class="mb-3">
                                        <a-tooltip :title="$p < 40 ? 'You do not have permission to modify this status' : ''" overlayClassName="change-tooltip-color">
                                            <a-select v-model="newUnit.status" style="width:100%" :disabled="$p < 40">
                                                <a-select-option v-for="vis in status" :key="vis.value">{{ vis.label }}</a-select-option>
                                            </a-select>
                                        </a-tooltip>
                                    </div>
                                    <div class="dF aC" style="justify-content:space-between">
                                        <h6 @click="deleteUnit" v-if="this.$route.params.id != null"  style="color:var(--orange); margin-bottom:0; text-decoration:underline; cursor:pointer">DELETE UNIT</h6>
                                        <div v-else></div>
                                        <a-button @click="submit" type="primary" style="width:120px;"  size="large">{{!this.$route.params.id ? 'SAVE' : 'UPDATE'}}</a-button>
                                    </div>
                                </div>
                            </div>
                            <div style="background-color:#fff;" class="mt-5">
                                <div class="pl-3 pt-3 dF aC">
                                    <span class="mr-2"><strong>Thumbnail Image</strong></span>
                                    <a-tooltip title="If you want to display something other then the main floor plan image, upload here." overlayClassName="change-tooltip-color">
                                        <svg style="color:var(--orange)" viewBox="64 64 896 896" data-icon="question-circle" width="15" height="15" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0 1 30.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1 0 80 0 40 40 0 1 0-80 0z"></path></svg>
                                    </a-tooltip>
                                </div>
                                <hr style="margin-bottom:0px">
                                <div style="padding:24px 10px">
                                    <ImageBox :img="newUnit.image" @callback="floorPlanImg" @previewImage="previewGallery" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a-tab-pane>
            </a-tabs>
        </div>
      </div>
  </div>
</template>

<script>

import GalleryImage from '@/components/condounits/GalleryImage'
import FloorImage from '@/components/condounits/FloorImage'
import SettingModal from '@/components/condounits/SettingModal'
import ImageBox from '@/components/common/ImageBox'
import bhLoading from 'bh-mod/components/common/Loading'
import moment from 'moment'
export default {
	components: {
		ImageBox, bhLoading, GalleryImage, SettingModal, FloorImage
	},
	data() {
		return {
			updateField: 0,
			active: false,
			change: false,
			unitGroupChange: false,
			updateChecker: false,
			currentTab: 'parking',
			delete: false,
			create: false,
			imageArray: [],
			imageArray2: [],
			openTab: '1',
			lockerSource: [],
			priceSource: [],
			parkingSource: [],
			previewModal: {
				visible: false,
				item: ''
			},
			loading: false,
			unitGroup: '',
			previous: '',
			hasRoof: false,
			hasPenthouse: false,
			hasCondo: false,
			hasDen: false,
			hasTerrace: false,
			previewImage: false,
			editPage: false,
			prevImg: '',
			showOptions: '',
			salesStatus: [
				{ label: 'Available', value: 'available' },
				{ label: 'Unavailable', value: 'unavailable' },
				{ label: 'Sold', value: 'sold' },
				{ label: 'Hold', value: 'hold' },
			],
			status: [
				{ label: 'Draft', value: 'draft' },
				{ label: 'Published', value: 'published' },
			],
			views: [
				{ label: 'Not Applicable', value: 'none' },
				{ label: 'North', value: 'north' },
				{ label: 'South', value: 'south' },
				{ label: 'East', value: 'east' },
				{ label: 'West', value: 'west' },
				{ label: 'North East', value: 'northEast' },
				{ label: 'South East', value: 'southEast' },
				{ label: 'South West', value: 'southWest' },
				{ label: 'North West', value: 'northwest' },
				{ label: 'North East South', value: 'northEastSouth' },
				{ label: 'East South West', value: 'eastSouthWest' },
				{ label: 'South West North', value: 'southWestNorth' },
				{ label: 'West North East', value: 'westNorthEast' },
				{ label: 'All', value: 'all' },
			],
			newImage: '',
			removeParking: [],
			removeLockers: [],
			removeBikeRacks: [],
			newUnit: {
				unitGroup: '',
				unitNumber: '',
				unitCode: '',
				name: '',
				salesStatus: 'available',
				status: 'draft',
				suffix: '',
				streetNumber: '',
				street: '',
				postal: '',
				city: '',
				region: '',
				pdf: '',
				features: '',
				image: '',
				galleryImage: [],
				showMarketingFloors: false,
				media: {
					mediaImage: [],
					mediaVideo: '',
					media360: '',
				},
				packages: [
					{
						floors: [],
						price: '',
						sqft: '',
						extSqft: 0,
						exposure: 'none',
						ceilHeight: 0,
						maxParking: 0,
						maxLocker: 0,
						maxBikeRack: 0,
						beds: 0,
						baths: 0,
						powderRooms: 0,
						hasDen: false,
						hasCondo: false,
						hasPenthouse: false,
						hasRoof: false,
						hasTerrace: false,
						occupancy: '',
						tax: 0,
						maintenance: 0,
						penthouseImage: '',
						penthouseImage2: '',
						condoImage: '',
						roofImage: '',
						terraceImage: '',
						terraceImage2: '',
						stories: 1,
						garages: 0,
						other: {
							name: '',
							image: '',
							visible: false,
							cf: {},
							premiums: {},
							addons: {}
						}
					}
				],
			}
		}
	},
	watch: {
		newUnit: {
			handler(val) {
				if (this.updateChecker == false) {
					this.updateChecker = true
				} else {
					this.change = true
				}

			},
			deep: true
		},
		premiums: {
			handler(val) {
				if (!Object.keys(this.newUnit.packages[0].other.premiums).includes(val[val.length - 1].id)) {
					let premiumObj = { value: '', active: false, name: '' }
					premiumObj.value = val[val.length - 1].value
					premiumObj.name = val[val.length - 1].name
					this.newUnit.packages[0].other.premiums[val[val.length - 1].id] = premiumObj
				}
			}
		},
		'$route': {
			immediate: true,
			handler(val) {
				this.$store.commit('SHOW_UNIT_PAGE', 'all')
				this.$store.commit('SHOW_FLOOR', '0')
				if (val.path.includes('/unitdetails') && Object.keys(val.params).length == 0) {
					let crumbs = JSON.parse(JSON.stringify(this.$store.state.crumbs))
					if (!crumbs.length || crumbs[crumbs.length - 1].name != 'Add New Unit') crumbs.push({ name: 'Add New Unit' })
					this.$store.commit('SET_CRUMBS', crumbs)
				} else if (val.path.includes('unitdetails') && val.params && val.params.id && val.params.id != '') {
					let crumbs = JSON.parse(JSON.stringify(this.$store.state.crumbs))
					if (crumbs.length && crumbs[crumbs.length - 1].name == 'Add New Unit') {
						crumbs.splice(crumbs.length - 1, 1)
						crumbs.push({ name: `Unit ${this.$store.state.condoUnits.units[val.params.id].unitNumber} - ${this.$store.state.condoUnits.units[val.params.id].name}` })
					} else if (crumbs.length && crumbs[crumbs.length - 1].name != `Unit ${this.$store.state.condoUnits.units[val.params.id].unitNumber} - ${this.$store.state.condoUnits.units[val.params.id].name}`) {
						crumbs.push({ name: `Unit ${this.$store.state.condoUnits.units[val.params.id].unitNumber} - ${this.$store.state.condoUnits.units[val.params.id].name}` })
					} else if (crumbs.length == 0) {
						crumbs.push({ name: `Unit ${this.$store.state.condoUnits.units[val.params.id].unitNumber} - ${this.$store.state.condoUnits.units[val.params.id].name}` })
					}
					this.$store.commit('SET_CRUMBS', crumbs)
				}
				this.editPageStart()
			},

		},
		unitGroup: {
			handler(val) {
				this.unitGroupChange = true
			}
		},
		originalUnit: {
			deep: true,
			handler(val) {
				if (Object.keys(val).length !== 0) {
					this.editPageStart()
				} else {
					this.$router.push('/showunits')
				}
			}
		},
		openTab(val) {
			this.openTab = val
		}
	},
	computed: {
		settings() {
			if (this.$store.state.condoUnits.allSettings && this.$store.state.condoUnits.allSettings.app && this.$store.state.condoUnits.allSettings.app.options) return this.$store.state.condoUnits.allSettings.app.options;
			return {}
		},
		condoAddress() {
			return this.settings.address || {
				streetNumber: '',
				street: '',
				postal: '',
				city: '',
				region: '',
			}
		},
		highestFloor() {
			let highest = ''
			let floors = this.$store.state.condoUnits.floors
			let order = floors.map(x => x.order)
			let highestOrder = Math.max(...order)
			let find = floors.find(x => x.order == highestOrder)
			if (find) highest = find.id
			return highest
		},
		deleteFloor() {
			return this.$store.state.condoUnits.deleteFloor
		},
		fields() {
			if (this.$store.state.condoUnits.allSettings && this.$store.state.condoUnits.allSettings.app && this.$store.state.condoUnits.allSettings.app.options && this.$store.state.condoUnits.allSettings.app.options.customFields) {
				let fields = {}
				JSON.parse(JSON.stringify(this.$store.state.condoUnits.allSettings.app.options.customFields)).forEach(x => {
					fields[x.id] = x
					fields[x.id].value = x.defaultAnswer
				})
				return fields
			}
			return {};
		},
		premiums() {
			if (this.$store.state.condoUnits.allSettings && this.$store.state.condoUnits.allSettings.app && this.$store.state.condoUnits.allSettings.app.options && this.$store.state.condoUnits.allSettings.app.options.premiums && this.$store.state.condoUnits.allSettings.app.options.premiums.length) {
				return this.$store.state.condoUnits.allSettings.app.options.premiums
			}
			return [
				{
					name: 'Floor Premium',
					value: '',
					id: 'floorPremium',
				},
				{
					name: 'View Premium',
					value: '',
					id: 'viewPremium',
				},
				{
					name: 'Balcony Premium',
					value: '',
					id: 'balconyPremium',
				},
				{
					name: 'Terrace Premium',
					value: '',
					id: 'terracePremium',
				},
			]
		},
		unitPrice() {
			let units = Object.values(this.$store.state.condoUnits.units)
			let price = []
			units.forEach(unit => {
				price.push(unit.packages[0].price && unit.packages[0].price.toString() || 0)
			})
			return price
		},
		measurement() {
			return this.$store.getters.measurement
		},
		instance() {
			return this.$store.state.instance
		},
		country() {
			let { country = "" } = this.instance;
			if (!country) country = "";
			country = country.toLowerCase().trim();
			return country === "canada" || country === "ca" ? "CA" : "USA";
		},
		appData() {
			return this.$store.state.appData
		},
		originalUnit() {
			if (this.$route.params && this.$route.params.id) {
				let unit = this.$store.state.condoUnits.units[this.$route.params.id]
				return unit || {}
			} else {
				return {}
			}
		},
		floors() {
			return this.$store.state.condoUnits.floors
		}
	},
	methods: {
		deleteAddon(addon, type) {
			if (this.$p < 40) return this.$message.error('You do not have permission to delete add on')

			this.$confirm({
				title: `Do you want to delete this selected ${type} add on?`,
				content: h => <div>If you delete an add on from this page, it will also be removed from the SETTINGS section. To unlink the add on from the unit without deleting it, please go to the SETTINGS section, edit the add on, and unlink it from the unit.</div>,
				okText: 'Delete',
				okType: 'danger',
				cancelText: 'Cancel',
				centered: true,
				onOk: () => {
					if (type == 'parking') {
						this.removeParking.push(addon)
						let newUnit = JSON.parse(JSON.stringify(this.newUnit))
						let index = newUnit.packages[0].other.addons.parking.findIndex(x => x.id == addon)
						if (index != -1) {
							newUnit.packages[0].other.addons.parking.splice(index, 1)
						}
						this.newUnit = newUnit
					} else if (type == 'lockers') {
						this.removeLockers.push(addon)
						let newUnit = JSON.parse(JSON.stringify(this.newUnit))
						let index = newUnit.packages[0].other.addons.lockers.findIndex(x => x.id == addon)
						if (index != -1) {
							newUnit.packages[0].other.addons.lockers.splice(index, 1)
						}
						this.newUnit = newUnit
					} else {
						this.removeBikeRacks.push(addon)
						let newUnit = JSON.parse(JSON.stringify(this.newUnit))
						let index = newUnit.packages[0].other.addons.bikeRacks.findIndex(x => x.id == addon)
						if (index != -1) {
							newUnit.packages[0].other.addons.bikeRacks.splice(index, 1)
						}
						this.newUnit = newUnit
					}
				}
			})
		},
		addAddon() {
			if (this.currentTab == 'parking') {
				if (this.$route.params && !this.$route.params.id) return this.$message.error('Please save the unit before adding a parking spot.')
				if (this.newUnit.packages[0].maxParking <= (this.newUnit.packages[0].other.addons.parking || []).length || this.newUnit.packages[0].maxParking == null) return this.$message.error('This unit already has the maximum number of parking spots.')
				let newUnit = JSON.parse(JSON.stringify(this.newUnit))
				newUnit.packages[0].other.addons.parking.push({
					id: Date.now(),
					pID: '',
					name: '',
					price: 0,
					unitId: this.$route.params.id,
					description: '',
					type: '',
					status: 'available',
					tags: []
				})
				this.newUnit = newUnit
			} else if (this.currentTab == 'lockers') {
				if (this.$route.params && !this.$route.params.id) return this.$message.error('Please save the unit before adding a locker.')
				if (this.newUnit.packages[0].maxLocker <= (this.newUnit.packages[0].other.addons.lockers || []).length || this.newUnit.packages[0].maxLocker == null) return this.$message.error('This unit already has the maximum number of lockers.')
				let newUnit = JSON.parse(JSON.stringify(this.newUnit))
				newUnit.packages[0].other.addons.lockers.push({
					id: Date.now(),
					lID: '',
					name: '',
					price: 0,
					unitId: this.$route.params.id,
					description: '',
					status: 'available',
					tags: []
				})
				this.newUnit = newUnit
			} else if (this.currentTab == 'bikeRacks') {
				if (this.$route.params && !this.$route.params.id) return this.$message.error('Please save the unit before adding a bike rack.')
				if (this.newUnit.packages[0].maxBikeRack <= (this.newUnit.packages[0].other.addons.bikeRacks || []).length || this.newUnit.packages[0].maxBikeRack == null) return this.$message.error('This unit already has the maximum number of bike racks.')
				let newUnit = JSON.parse(JSON.stringify(this.newUnit))
				newUnit.packages[0].other.addons.bikeRacks.push({
					id: Date.now(),
					bID: '',
					name: '',
					price: 0,
					unitId: this.$route.params.id,
					description: '',
					status: 'available',
					tags: []
				})
				this.newUnit = newUnit
			}
		},
		changeCondoType(val) {
			this.newUnit.packages[0].floors = []
			this.imageArray = []
			this.imageArray2 = []
		},
		selectStorey(val) {
			this.newUnit.packages[0].stories = val
		},
		updateHasPenthouse(data) {
			this.newUnit.packages[0].hasPenthouse = data
		},
		updateHasTerrace(data) {
			this.newUnit.packages[0].hasTerrace = data
		},
		updatePenthouseImage(url) {
			this.newUnit.packages[0].penthouseImage = url
		},
		updatePenthouseImage2(url) {
			this.newUnit.packages[0].penthouseImage2 = url
		},
		updateTerraceImage(url) {
			this.newUnit.packages[0].terraceImage = url
		},
		updateTerraceImage2(url) {
			this.newUnit.packages[0].terraceImage2 = url
		},
		toggleClass() {
			if (this.active == false) {
				return ''
			} else {
				return 'sticky-nav'
			}
		},
		changeActive(e, id) {
			if (this.newUnit.packages[0].other.premiums[id] && this.newUnit.packages[0].other.premiums[id].hasOwnProperty('active') && this.newUnit.packages[0].other && this.newUnit.packages[0].other.premiums) {
				this.newUnit.packages[0].other.premiums[id].active = e.target.checked
			}
		},
		addCustomField() {
			this.$store.commit('OPEN_MODAL', 'field')
		},
		addPremium() {
			this.$store.commit('OPEN_MODAL', 'premium')
		},
		applyMulField(e, id) {
			this.newUnit.packages[0].other.cf[id] = e
		},
		changeCheck(e, id) {
			this.newUnit.packages[0].other.cf[id] = e.target.value;
			this.updateField = Date.now()
		},
		fieldChanged() {
			this.updateField = Date.now()
		},
		backButton() {
			let crumbs = JSON.parse(JSON.stringify(this.$store.state.crumbs))
			if (crumbs.length == 1 && this.floors && this.floors.length) {
				this.$store.commit('SHOW_FLOOR', this.floors[0].id)
			} else if (crumbs.length > 1 && crumbs[crumbs.length - 2].id) {
				this.$store.commit('SHOW_FLOOR', crumbs[crumbs.length - 2].id)
			} else if (crumbs.length > 1 && !crumbs[crumbs.length - 2].id) {
				if (crumbs[0].id) {
					this.$store.commit('SHOW_FLOOR', crumbs[0].id)
					this.$store.commit('SHOW_UNIT_PAGE', crumbs[1].name)
				}
			}
			if (!this.$route.meta.showUnits) this.$router.push('/showunits')
		},
		moment,
		galleryImg() {
		},
		galleryImages(data) {
			let array = []
			let floorsArray = JSON.parse(JSON.stringify(this.newUnit.packages[0].floors));
			data.forEach((x, xI) => {
				let item = {
					image: ''
				}
				item.image = x.url
				if (floorsArray.length > xI) {
					if (floorsArray[xI].image2) {
						item.image2 = floorsArray[xI].image2;
					}
				}

				array.push(item)
			})
			this.newUnit.packages[0].floors = array
			console.log('UNIT FLOORS', this.newUnit.packages[0].floors);
		},
		marketingImages(data) {
			console.log('Marketing Images Data', data);
			let floorsArray = JSON.parse(JSON.stringify(this.newUnit.packages[0].floors));
			data.forEach((x, xI) => {
                if (floorsArray[xI]) {
                    floorsArray[xI].image2 = x.url;
				}
			})
			this.newUnit.packages[0].floors = floorsArray;
			console.log('UNIT FLOORS', this.newUnit.packages[0].floors);
		},
		tabName(e) {
			this.openTab = e
		},
		priceSearch(searchText) {
			let temp = []
			for (var i in this.unitPrice) {
				temp.push(this.unitPrice[i])
			}
			let unique = [...new Set(temp)];
			if (searchText && searchText.length > 0) {
				this.priceSource = unique.filter(x => {
					return x.includes(searchText)
				});
			}
			else {
				return this.priceSource = []
			}

		},
		deleteMedia(index) {
			this.newUnit.media.mediaImage.splice(index, 1)
			this.$forceUpdate()
		},
		previewGallery(item) {
			this.previewModal.visible = true
			this.previewModal.item = item.url
		},
		closeModal() {
			this.previewModal.visible = false
		},
		deleteField(index) {
			this.newUnit.customFields.splice(index, 1)
		},
		addField() {
			this.$refs.field.validate(valid => {
				if (valid) {
					let Obj = JSON.parse(JSON.stringify(this.field))
					this.newUnit.customFields.push(Obj)
					this.field.key = ''
					this.field.value = ''
				} else {
					return false
				}
			})
		},
		selectedImg(item) {
			let url = {
				url: "",
				id: ""
			}
			url.url = item.url
			url.id = item.id
			if (!this.newUnit.media) {
				this.newUnit.media = {
					mediaImage: [],
					mediaVideo: '',
					media360: '',
				}
				this.newUnit.media.mediaImage.push(url)
				this.$forceUpdate()
			}
			else {
				this.newUnit.media.mediaImage.push(url)
				this.$forceUpdate()
			}
		},
		otherSwitch(val) {
			this.newUnit.packages[0].other.visible = val
			if (!val) {
				this.newUnit.packages[0].other.image = ''
				this.newUnit.packages[0].other.name = ''
			}
		},
		penthouseSwitch(val) {
			if (val) {
				this.newUnit.packages[0].condoImage = ''
				if (this.previous == '') {
					this.previous = 'penthouse'
					this.newUnit.packages[0].hasCondo = false
				}
				if (this.previous == 'condo') {
					this.previous = 'penthouse'
					this.newUnit.packages[0].hasCondo = false
				}
			}
			else {
				this.newUnit.packages[0].penthouseImage = ''
				this.newUnit.packages[0].penthouseImage2 = ''
				this.previous = ''
			}
		},
		condoSwitch(val) {
			if (val) {
				this.newUnit.packages[0].penthouseImage = ''
				this.newUnit.packages[0].penthouseImage2 = ''
				if (this.previous == '') {
					this.previous = 'condo'
					this.newUnit.packages[0].hasPenthouse = false
				}
				if (this.previous == 'penthouse') {
					this.previous = 'condo'
					this.newUnit.packages[0].hasPenthouse = false
				}
			}
			else {
				this.newUnit.packages[0].condoImage = ''
				this.previous = ''
			}

		},
		roofSwitch(val) {
			if (!val) this.newUnit.packages[0].roofImage = ''
		},
		terraceSwitch(val) {
			if (!val) {
				this.newUnit.packages[0].terraceImage = ''
				this.newUnit.packages[0].terraceImage2 = ''
			}
		},
		selectUnitGroup(value) {
			for (var i in this.floors) {
				if (value == this.floors[i].value) {
					this.newUnit.unitGroup = this.floors[i].id
				}
			}

		},
		deleteUnit() {
			let self = this
			if (this.$p < 40) return this.$message.error('You do not have permission to delete units')
			this.$confirm({
				title: "Delete",
				content: h => <div>Do you want to delete this unit?</div>,
				okText: 'Delete',
				okType: 'danger',
				cancelText: 'Cancel',
				centered: true,
				onOk() {
					self.loading = true
					self.$api.delete(`/units/${self.instance.id}/${self.$route.params.id}`, { buildingType: 'condounits' }).then(({ data }) => {
						self.$store.commit('SHOW_UNIT_PAGE', 'all')
						if (self.$store.state.crumbs.length && self.$store.state.crumbs[0].id) {
							self.$store.commit('SHOW_FLOOR', self.$store.state.crumbs[0].id)
						}
						self.$store.commit('DELETE_UNIT', data.id)
						self.loading = false
						self.delete = true
						self.$router.push('/showunits')
					}).catch(err => {
						self.loading = false
					})
				},
				onCancel() {
					console.log('Cancel')
				}
			})
		},
		editPageStart() {
			if (this.$route.params.id) {
				this.updateChecker = false
				if (this.$store.state.condoUnits.units[this.$route.params.id]) {
					this.newUnit = JSON.parse(JSON.stringify(this.$store.state.condoUnits.units[this.$route.params.id]))

					if (!this.newUnit.packages?.length) {
						this.newUnit.packages = [
							{
								floors: [],
								price: '',
								sqft: '',
								extSqft: 0,
								exposure: 'none',
								ceilHeight: 0,
								maxParking: 0,
								maxLocker: 0,
								maxBikeRack: 0,
								beds: 0,
								baths: 0,
								powderRooms: 0,
								hasDen: false,
								hasCondo: false,
								hasPenthouse: false,
								hasRoof: false,
								hasTerrace: false,
								occupancy: '',
								tax: 0,
								maintenance: 0,
								penthouseImage: '',
								penthouseImage2: '',
								condoImage: '',
								roofImage: '',
								terraceImage: '',
								terraceImage2: '',
								stories: 1,
								garages: 0,
								other: {
									name: '',
									image: '',
									visible: false,
									cf: {},
									premiums: {},
									addons: {}
								}
							}
						]
					}

					if (!this.newUnit.packages[0].stories) this.newUnit.packages[0].stories = 1
					if (!this.newUnit.packages[0].garages) this.newUnit.packages[0].garages = 0
					if (!this.newUnit.packages[0].powderRooms) this.newUnit.packages[0].powderRooms = 0

					if (this.newUnit.packages[0].hasCondo) this.newUnit.packages[0].hasCondo = 'true'
					else if (!this.newUnit.packages[0].hasCondo) this.newUnit.packages[0].hasCondo = 'false'

					if (this.newUnit.packages[0].hasDen) this.newUnit.packages[0].hasDen = 'true'
					else if (!this.newUnit.packages[0].hasDen) this.newUnit.packages[0].hasDen = 'false'

					if (this.newUnit.packages[0].price && typeof (this.newUnit.packages[0].price) == "number") {
						this.newUnit.packages[0].price = this.newUnit.packages[0].price.toString()
					}
					if (this.newUnit.packages[0].occupancy && typeof (this.newUnit.packages[0].occupancy) == 'number') {
						this.newUnit.packages[0].occupancy = this.moment(this.newUnit.packages[0].occupancy).format()
					}
					if (!this.newUnit.media) {
						this.newUnit.media = {
							mediaImage: [],
							mediaVideo: '',
							media360: '',
						}
					}

					if (!this.newUnit.packages[0].other) this.newUnit.packages[0].other = {}
					if (!this.newUnit.packages[0].other.cf) this.newUnit.packages[0].other.cf = {}
					if (!this.newUnit.packages[0].other.addons) this.newUnit.packages[0].other.addons = {}

					this.newUnit.packages[0].other = {
						...this.newUnit.packages[0].other,
						name: this.newUnit.packages[0].other.name || '',
						image: this.newUnit.packages[0].other.image || '',
						visible: this.newUnit.packages[0].other.visible || false,
						cf: this.newUnit.packages[0].other.cf || {},
						premiums: this.newUnit.packages[0].other.premiums || {},
						addons: { parking: [], lockers: [], bikeRacks: [], ...this.newUnit.packages[0].other.addons }
					}

					let fields = {}
					Object.values(this.fields).forEach(field => {
						if (Object.keys(this.newUnit.packages[0].other.cf).includes(field.id) && Object.keys(this.newUnit.packages[0].other).length != 0 && Object.keys(this.newUnit.packages[0].other.cf).length != 0 && this.newUnit.packages[0].other && this.newUnit.packages[0].other.cf) {
							fields[field.id] = this.newUnit.packages[0].other.cf[field.id]
						}
					})
					let premiums = {}
					this.premiums.forEach(premium => {
						if (Object.keys(this.newUnit.packages[0].other.premiums).includes(premium.id)) {
							let premiumObj = { value: '', active: false, name: '' }
							// console.log('hii', premium)
							if (this.newUnit.packages[0].other.premiums[premium.id] != null) {
								if (this.newUnit.packages[0].other.premiums[premium.id].hasOwnProperty('value')) premiumObj.value = this.newUnit.packages[0].other.premiums[premium.id].value
								else premiumObj.value = this.newUnit.packages[0].other.premiums[premium.id]
								if (this.newUnit.packages[0].other.premiums[premium.id].hasOwnProperty('active')) premiumObj.active = this.newUnit.packages[0].other.premiums[premium.id].active
								if (this.newUnit.packages[0].other.premiums[premium.id].hasOwnProperty('name')) premiumObj.name = this.newUnit.packages[0].other.premiums[premium.id].name
							}

							premiums[premium.id] = premiumObj
						} else {
							let premiumObj = { value: '', active: false, name: '' }
							premiumObj.value = premium.value
							premiumObj.name = premium.name
							premiums[premium.id] = premiumObj
						}
					})

					if (this.newUnit.packages[0].other && this.newUnit.packages[0].other.cf) this.newUnit.packages[0].other.cf = fields
					if (this.newUnit.packages[0].other && this.newUnit.packages[0].other.premiums) this.newUnit.packages[0].other.premiums = premiums
					let array = []
					let array2 = []
					this.newUnit.packages[0].floors.forEach(floor => {
						if (floor.image) {
							let item = {
								url: ''
							}
							let item2 = {
								url: ''
							}
							item.url = floor.image
							if (this.newUnit.packages[0].hasCondo) {
								if (floor.image2) {
									item2.url = floor.image2;
									array2.push(item2)
								}
							} else {
								item2.url = floor.image2 ? floor.image2 : floor.image;
							}
							array.push(item)
						}
					})
					this.imageArray = array
					this.imageArray2 = array2
					this.unitGroup = this.newUnit.unitGroup.name
					this.newUnit.streetNumber = this.newUnit.streetNumber || this.condoAddress.streetNumber;
					this.newUnit.street = this.newUnit.street || this.condoAddress.street;
					this.newUnit.postal = this.newUnit.postal || this.condoAddress.postal;
					this.newUnit.city = this.newUnit.city || this.condoAddress.city;
					this.newUnit.region = this.newUnit.region || this.condoAddress.region;
				}
			} else {
				if (!this.newUnit.packages[0].stories) this.newUnit.packages[0].stories = 1
				if (!this.newUnit.packages[0].garages) this.newUnit.packages[0].garages = 0

				if (this.newUnit.packages[0].hasCondo) this.newUnit.packages[0].hasCondo = 'true'
				else if (!this.newUnit.packages[0].hasCondo) this.newUnit.packages[0].hasCondo = 'false'

				if (this.newUnit.packages[0].hasDen) this.newUnit.packages[0].hasDen = 'true'
				else if (!this.newUnit.packages[0].hasDen) this.newUnit.packages[0].hasDen = 'false'

				if (!this.newUnit.packages[0].other) this.newUnit.packages[0].other = {}
				if (!this.newUnit.packages[0].other.cf) this.newUnit.packages[0].other.cf = {}
				if (!this.newUnit.packages[0].other.addons) this.newUnit.packages[0].other.addons = {}

				this.newUnit.packages[0].other = {
					...this.newUnit.packages[0].other,
					name: this.newUnit.packages[0].other.name || '',
					image: this.newUnit.packages[0].other.image || '',
					visible: this.newUnit.packages[0].other.visible || false,
					cf: this.newUnit.packages[0].other.cf || {},
					premiums: this.newUnit.packages[0].other.premiums || {},
					addons: { parking: [], lockers: [], bikeRacks: [], ...this.newUnit.packages[0].other.addons }
				}
				Object.keys(this.fields).forEach(field => {
					this.newUnit.packages[0].other.cf[field] = this.fields[field].defaultAnswer
				})
				let premiums = {}
				this.premiums.forEach(premium => {
					premiums[premium.id] = { value: premium.value, active: false, name: premium.name }
				})
				this.newUnit.packages[0].other.premiums = premiums

				this.newUnit.streetNumber = this.newUnit.streetNumber || this.condoAddress.streetNumber;
				this.newUnit.street = this.newUnit.street || this.condoAddress.street;
				this.newUnit.postal = this.newUnit.postal || this.condoAddress.postal;
				this.newUnit.city = this.newUnit.city || this.condoAddress.city;
				this.newUnit.region = this.newUnit.region || this.condoAddress.region;
			}
			this.editPage = true
		},
		req(msg, required = true) {
			return { required: required, message: msg }
		},
		previewButton(index) {
			this.previewImage = true
			this.prevImg = this.newUnit.floorImage[index]
		},
		submit() {
			if (!this.$route.params.id) return this.createUnit()
			else {
				if (this.$p < 20 && this.newUnit.status === 'published') return this.$message.error('You do not have permission to modify published units')
				return this.updateUnit()
			}
		},
		createUnit() {
			if (this.newUnit.packages[0].floors.length < 1) return this.$message.error('Unit Needs a Floor Plan Image')
			if (this.newUnit.packages[0].hasCondo == 'false' && !this.unitGroup.trim()) return this.$message.error('Unit Needs a Floor')
			if (!(this.newUnit.packages[0].sqft > 0)) return this.$message.error('Unit Needs an Interior Square Footage')
			if (!(this.newUnit.packages[0].beds > 0)) return this.$message.error('Unit Needs a Bedroom')
			if (!(this.newUnit.packages[0].baths > 0)) return this.$message.error('Unit Needs a Bathroom')

			this.$refs.newUnit.validate(valid => {
				if (valid) {
					this.newUnit.unitGroup = this.unitGroup
					let sendObj = JSON.parse(JSON.stringify(this.newUnit))
					if (!sendObj.image) sendObj.image = sendObj.packages[0].floors[0].image
					let firstFloor = this.floors[0].id

					if (sendObj.packages[0].hasCondo == 'true') sendObj.packages[0].hasCondo = true
					else if (sendObj.packages[0].hasCondo == 'false') sendObj.packages[0].hasCondo = false

					if (sendObj.packages[0].hasDen == 'true') sendObj.packages[0].hasDen = true
					else if (sendObj.packages[0].hasDen == 'false') sendObj.packages[0].hasDen = false

					if (sendObj.packages[0].hasCondo) {
						sendObj.packages[0].hasDen = false
						sendObj.unitGroup = firstFloor
						sendObj.packages[0].hasTerrace = false
						sendObj.packages[0].hasPenthouse = false
					} else {
						sendObj.packages[0].garages = 0
						sendObj.packages[0].stories = 1
					}

					sendObj.instance = this.instance.id
					sendObj.buildingType = 'condounits'
					sendObj.packages[0].occupancy = this.moment(sendObj.packages[0].occupancy).format('X') * 1000
					if (sendObj.packages[0].price && Number.isNaN(Number(sendObj.packages[0].price))) {
						return this.$message.error("The Price can only be digits")
					}
					if (sendObj.packages[0].price && typeof (sendObj.packages[0].price) == "string") sendObj.packages[0].price = parseFloat(sendObj.packages[0].price)
					this.loading = true
					this.$api.post(`/units/${this.instance.id}`, sendObj).then(({ data }) => {
						this.$store.commit('SET_PROP', { where: ['units', data.id], what: data })
						this.loading = false
						this.create = true
						this.$router.push(`/unitdetails/${data.id}`)
					}).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					}).finally(() => {
						this.loading = false
					})
				} else {
					return false;
				}
			})




		},
		updateUnit() {
			if (this.newUnit.packages[0].floors.length < 1) return this.$message.error('Unit Needs a Floor Plan Image')
			if (this.newUnit.packages[0].hasCondo == 'false' && !this.unitGroup.trim()) return this.$message.error('Unit Needs a Floor')
			if (!(this.newUnit.packages[0].sqft > 0)) return this.$message.error('Unit Needs an Interior Square Footage')
			if (!(this.newUnit.packages[0].beds > 0)) return this.$message.error('Unit Needs a Bedroom')
			if (!(this.newUnit.packages[0].baths > 0)) return this.$message.error('Unit Needs a Bathroom')

			if (!this.newUnit.packages[0].other) this.newUnit.packages[0].other = {}
			if (!this.newUnit.packages[0].other.addons) this.newUnit.packages[0].other.addons = {}

			this.newUnit.packages[0].other.addons = { parking: [], lockers: [], bikeRacks: [], ...this.newUnit.packages[0].other.addons }

			if ((this.newUnit.packages[0].maxParking < this.newUnit.packages[0].other.addons.parking.length) ||
				(this.newUnit.packages[0].maxLocker < this.newUnit.packages[0].other.addons.lockers.length) ||
				(this.newUnit.packages[0].maxBikeRack < this.newUnit.packages[0].other.addons.bikeRacks.length)) return this.$message.error('Please ensure the number of add ons does not exceed the maximum number of parking spots, lockers, and bike racks')

			let addOns = false

			this.newUnit.packages[0].other.addons.parking.forEach(x => {
				if (!x.pID || !x.name || !x.price || !x.type) return addOns = true
			})
			this.newUnit.packages[0].other.addons.lockers.forEach(x => {
				if (!x.lID || !x.name || !x.price) return addOns = true
			})
			this.newUnit.packages[0].other.addons.bikeRacks.forEach(x => {
				if (!x.bID || !x.name || !x.price) return addOns = true
			})

			if (addOns) {
				return this.$message.error('Please fill out all the add on fields!')
			}

			let floor = this.floors.find(floor => floor.name == this.unitGroup)
			let firstFloor = this.floors[0].id

			if (!floor) {
				this.newUnit.unitGroup = this.unitGroup
			}
			else {
				this.newUnit.unitGroup = floor.id
			}

			this.$refs.newUnit.validate(async valid => {
				if (valid) {
					if (Object.keys(this.fields) > Object.keys(this.newUnit.packages[0].other.cf) && this.newUnit.packages[0].other && this.newUnit.packages[0].other.cf) {
						Object.values(this.fields).forEach(field => {
							if (!Object.keys(this.newUnit.packages[0].other.cf).includes(field.id)) {
								this.newUnit.packages[0].other.cf[field.id] = field.defaultAnswer
							}
						})
					}
					this.premiums.forEach(premium => {
						if (!Object.keys(this.newUnit.packages[0].other.premiums).includes(premium.id)) {
							this.newUnit.packages[0].other.premiums[premium.id].value = parseInt(premium.value)
							this.newUnit.packages[0].other.premiums[premium.id].active = false
						}
					})
					let sendObj = JSON.parse(JSON.stringify(this.newUnit))
					if (!sendObj.image) sendObj.image = sendObj.packages[0].floors[0].image

					if (sendObj.packages[0].hasCondo == 'true') sendObj.packages[0].hasCondo = true
					else if (sendObj.packages[0].hasCondo == 'false') sendObj.packages[0].hasCondo = false

					if (sendObj.packages[0].hasDen == 'true') sendObj.packages[0].hasDen = true
					else if (sendObj.packages[0].hasDen == 'false') sendObj.packages[0].hasDen = false

					if (sendObj.packages[0].hasCondo) {
						sendObj.packages[0].hasDen = false
						sendObj.unitGroup = firstFloor
						sendObj.packages[0].hasTerrace = false
						sendObj.packages[0].hasPenthouse = false
					} else {
						sendObj.packages[0].garages = 0
						sendObj.packages[0].stories = 1
					}

					sendObj.instance = this.instance.id
					sendObj.buildingType = 'condounits'
					sendObj.packages[0].occupancy = this.moment(sendObj.packages[0].occupancy).format('X') * 1000
					if (sendObj.packages[0].price && Number.isNaN(Number(sendObj.packages[0].price))) {
						return this.$message.error("The Price can only be digits")
					}
					if (sendObj.packages[0].price && typeof (sendObj.packages[0].price) == "string") sendObj.packages[0].price = parseFloat(sendObj.packages[0].price)
					this.loading = true
					try {
						let { data } = await this.$api.put(`/units/${this.instance.id}/${sendObj.id}?type=condounits`, sendObj)
						this.$store.commit('UPDATE_UNIT', data)
					} catch (err) {
						this.loading = false;
						if (err?.response?.status !== 400) {
							this.$message.error(this.$err(err))
						}
						return;
					}

					if (!this.settings.addons) {
						this.$store.commit('UPDATE_ADDON_SETTINGS')
					}

					let packageAddons = JSON.parse(JSON.stringify(sendObj.packages[0].other.addons))
					let settingAddons = JSON.parse(JSON.stringify(this.settings.addons))

					let packageParking = packageAddons.parking
					let packageLockers = packageAddons.lockers
					let packageBikeRacks = packageAddons.bikeRacks
					let settingParking = settingAddons.parking.map(x => x = x.id)
					let settingLockers = settingAddons.lockers.map(x => x = x.id)
					let settingBikeRacks = settingAddons.bikeRacks.map(x => x = x.id)

					let sendObj2 = JSON.parse(JSON.stringify(this.settings))
					sendObj2.addons.parking = sendObj2.addons.parking.filter(x => {
						return !this.removeParking.includes(x.id)
					})
					sendObj2.addons.lockers = sendObj2.addons.lockers.filter(x => {
						return !this.removeLockers.includes(x.id)
					})
					sendObj2.addons.bikeRacks = sendObj2.addons.bikeRacks.filter(x => {
						return !this.removeBikeRacks.includes(x.id)
					})

					packageParking.forEach(x => {
						if (!settingParking.includes(x.id)) {
							sendObj2.addons.parking.push(x)
						} else {
							sendObj2.addons.parking.forEach(y => {
								if (y.id == x.id) {
									y.pID = x.pID
									y.name = x.name
									y.price = x.price
									y.description = x.description
									y.type = x.type
									y.tags = x.tags
								}
							})
						}
					})
					packageLockers.forEach(x => {
						if (!settingLockers.includes(x.id)) {
							sendObj2.addons.lockers.push(x)
						} else {
							sendObj2.addons.lockers.forEach(y => {
								if (y.id == x.id) {
									y.lID = x.lID
									y.name = x.name
									y.price = x.price
									y.description = x.description
									y.tags = x.tags
								}
							})
						}
					})
					packageBikeRacks.forEach(x => {
						if (!settingBikeRacks.includes(x.id)) {
							sendObj2.addons.bikeRacks.push(x)
						} else {
							sendObj2.addons.bikeRacks.forEach(y => {
								if (y.id == x.id) {
									y.bID = x.bID
									y.name = x.name
									y.price = x.price
									y.description = x.description
									y.tags = x.tags
								}
							})
						}
					})

					await this.$api.post(`/settings/:instance/condounits`, { options: sendObj2 }).then(({ data }) => {
						this.$store.commit('UPDATE_SETTINGS', data)
						this.$message.success('Unit successfully updated!')
						this.loading = false
					}).catch(err => {
						this.loading = false
					})
				} else {
					this.loading = false
					return false;
				}
			})

		},
		selectedPdf(item) {
			let url = item.url
			this.newUnit.pdf = url
		},
		selectedFeaturePDF(item) {
			let url = item.url
			this.newUnit.features = url
		},
		floorPlanImg(data) {
			this.newUnit.image = data
		},
		roofImg(data) {
			this.newUnit.packages[0].roofImage = data
		},
		otherImg(data) {
			this.newUnit.packages[0].other.image = data
		},

	},
	beforeRouteLeave(to, from, next) {
		let message = ''
		if (!this.delete && !this.create && !this.deleteFloor && (this.change || this.unitGroupChange)) {
			let self = this
			this.$confirm({
				title: "Exit Page",
				content: h => <div>Are you sure you want to exit page? All your unsaved changes will be lost.</div>,
				okText: 'SAVE',
				okType: 'danger',
				cancelText: 'DISCARD',
				centered: true,
				async onOk() {
					if (self.newUnit.packages[0].floors.length < 1) {
						message = 'You need to add at least one floor'
						self.$message.error(message)
						return
					}
					else if (self.newUnit.packages[0].hasCondo == 'false' && !self.unitGroup.trim()) {
						message = 'Unit Needs a Floor'
						self.$message.error(message)
						return
					}
					else if (!(self.newUnit.packages[0].sqft > 0)) {
						message = 'Unit Needs an Interior Square Footage'
						self.$message.error(message)
						return
					}
					else if (!(self.newUnit.packages[0].beds > 0)) {
						message = 'Unit Needs a Bedroom'
						self.$message.error(message)
						return
					}
					else if (!(self.newUnit.packages[0].baths > 0)) {
						message = 'Unit Needs a Bathroom'
						self.$message.error(message)
						return
					}
					else {
						await self.submit()
						next()
					}
				},
				onCancel() {
					next()
				}
			})
		} else {
			this.delete = false
			this.create = false
			this.unitGroupChange = false

			next()
		}
	},
	mounted() {
		let scrollingElement = document.querySelector('.scrollingElement')
		scrollingElement?.addEventListener("scroll", () => {
			if (scrollingElement.scrollTop > 145) {
				this.active = true;
			} else {
				this.active = false;
			}
		})
	}
}
</script>

<style scoped>
.testtt {
    transition: margin-top 0.3s;
}
.zoomButton {
    opacity:0;
}
.previewImage:not(:hover) .zoomButton{
    opacity:0;
    transform: opacity 0.3s ease-in;
}
.previewImage:hover .zoomButton{
    opacity:1;
    border: 1px solid var(--orange);
    transform: opacity 0.3s ease-in;
}
.sticky-nav{
    transition: 10ms;
    position:fixed;
    width: 342px;
    z-index:10;
    top:160px;
}
</style>
<style>
    .change-tooltip-color .ant-tooltip-inner{
        background-color:#3f3356 !important;
    }
    .scrollingElement::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    .scrollingElement::-webkit-scrollbar-track {
        background: #F2F2F2;
    }

    .scrollingElement::-webkit-scrollbar-thumb {
        background: var(--grey-purple);;
        border-radius: 10px;
    }

    .scrollingElement::-webkit-scrollbar-thumb:hover {
        background: var(--orange);
    }
</style>
